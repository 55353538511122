import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/system";
import { Grid } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { TeamMainBox } from "../../components/CustomComponents/TeamBox";
import {
  TeamSectionTitleBox,
  TeamSectionBox,
} from "../../components/CustomComponents/TeamBox";
import { DesTypography } from "../../components/CustomComponents/NotificationTypography";
import { TeamSectionTitleTypography } from "../../components/CustomComponents/TeamTypography";
import { OverflowBox } from "../../components/CustomComponents/NotificationBox";
import { IOSSwitch } from "../../components/CustomComponents/IOSSwitch";
import Button from "@mui/material/Button";
import { ButtonPositionBox } from "../../components/CustomComponents/NotificationBox";
import {
  GithubMember,
  TeamMember,
  createTeam,
  matchTeamMembers,
  createInitialReportDuringOnboarding,
  saveAlertsScheduleDuringOnBoarding
} from "../../services/api";
import { GitHubSelect } from "./GitHubSelect";

import Loader from "../../components/Loader";
import avatar1 from "../../assets/images/Avatar1.svg";
import avatar2 from "../../assets/images/Avatar2.png";
import avatar3 from "../../assets/images/Avatar3.png";
import avatar4 from "../../assets/images/Avatar4.svg";
import avatar5 from "../../assets/images/Avatar5.svg";
import avatar6 from "../../assets/images/Avatar6.svg";
import slackIcon from "../../assets/images/Slack.svg";

// Need data structure to hold who has been selected;
interface SelectedTeamMember {
  index: number;

  selected: boolean;
}

const TeamsPage: React.FC = () => {

  const navigate = useNavigate();
  const organizationId = localStorage.getItem("organizationId") ?? "";
  const userId = localStorage.getItem("userId") ?? "";

  //const matchedTeamMembers = personalData().matchedTeamMembers;

  const [selectedTeamMembers, setSelectedTeamMember] = useState<
    SelectedTeamMember[]
  >([]);
  const [githubTeamMembers, setGithubUsersList] = useState<GithubMember[]>([]); //personalData().githubTeamMembers;
  const [allTeamMembers, setAllTeamMembers] = useState<TeamMember[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [mainPageConversationId, setConversationId] = useState<string>();
  const avatars = [avatar1, avatar2, avatar3, avatar4, avatar5, avatar6];
  const [selectAllTeamMembers, setSelectAllTeamMembers] = useState(false);
  const [switchChecked, setSwitchChecked] = useState<Record<number, boolean>>(
    {}
  );

  const handleChangeTeamMemberSelected = (index: number) => {
    selectedTeamMembers[index].selected = true
      ? !selectedTeamMembers[index].selected
      : false;
    setSelectedTeamMember(selectedTeamMembers.map((selected) => selected));
  };

  const handleChangeAll = () => {
    // If the current value is true, change it to false, otherwise change to true
    const allSelected: boolean = selectAllTeamMembers ? false : true;
    setSelectedTeamMember(
      selectedTeamMembers.map((selectedMember) => ({
        index: selectedMember.index,
        selected: allSelected,
      }))
    );
    setSelectAllTeamMembers(allSelected);
  };

  const handleSwitchChange = (Id: number) => {
    setSwitchChecked((prevState) => ({
      ...prevState,
      [Id]: !prevState[Id],
    }));
  };

  const handleNextButton = async () => {
    const teamMembers: TeamMember[] = selectedTeamMembers
      .filter((selectedMember) => selectedMember.selected)
      .map((selectedMember) => allTeamMembers[selectedMember.index]);

    await createTeam(teamMembers, organizationId, userId);

    // We can now save the Alerts. This may break if the user leaves the site.
    // If they leave before alerts were set, we will need to notify them 
    // We will need to take them to the alerts page, because they will already be logged in
    
    const selectAlerts = localStorage.getItem('selected-alerts');
    saveAlertsScheduleDuringOnBoarding(
        selectAlerts ? JSON.parse(selectAlerts) : [], 
        organizationId,
        userId,
    );
    localStorage.setItem('selected-alerts', '');

    if (mainPageConversationId) {
      navigate(`/conversation/${mainPageConversationId}`);
      return;
    }

    navigate("/", { state : {data : "TeamScreenOnboarding"}});
  };

  const fetchData = async () => {
    try {
      const matchedTeamMembersResponse = await matchTeamMembers(
        organizationId,
        userId
      );
      // const matchedTeamMembersResponse = personalData();
      console.log("Match team members ", matchedTeamMembersResponse);
      if (matchedTeamMembersResponse) {
        //createDefaultGithubUserIfNotExists(matchedTeamMembersResponse.matchedTeamMembers);
        setGithubUsersList(matchedTeamMembersResponse.githubTeamMembers);
        setAllTeamMembers(matchedTeamMembersResponse.matchedTeamMembers);
        setSelectedTeamMember(
          matchedTeamMembersResponse.matchedTeamMembers.map(
            (member, index) => ({ index: index, selected: false })
          )
        );
      }

      createInitialReportDuringOnboarding(organizationId, userId).then((reponse) => {
        if (reponse) {
          setConversationId(reponse.conversationId);
        }
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 10000)
  }, []); // eslint-disable-line

  return !isLoading ? (
    <div className="mainlayout">
      <TeamMainBox>
        <DesTypography>
          Mark the individuals you care about and want me to assist them
        </DesTypography>
        <Box
          sx={{
            padding: "10px",
            border: "1px solid rgba(0,0,0,0.25)",
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
          }}
        >
          <Grid container alignItems={"center"}>
            <Grid item xs={0.9}>
              <TeamSectionTitleBox>
                <Checkbox
                  key={0}
                  checked={selectAllTeamMembers}
                  onChange={handleChangeAll}
                  size="small"
                  color="info"
                />
              </TeamSectionTitleBox>
            </Grid>
            <Grid item xs={2}>
              <TeamSectionTitleBox>
                <TeamSectionTitleTypography>NAME</TeamSectionTitleTypography>
              </TeamSectionTitleBox>
            </Grid>
            <Grid item xs={2}>
              <TeamSectionTitleBox>
                <TeamSectionTitleTypography>
                  JIRA USER
                </TeamSectionTitleTypography>
              </TeamSectionTitleBox>
            </Grid>
            <Grid item xs={2}>
              <TeamSectionTitleBox>
                <TeamSectionTitleTypography>
                  SLACK USER
                </TeamSectionTitleTypography>
              </TeamSectionTitleBox>
            </Grid>
            <Grid item xs={2}>
              <TeamSectionTitleBox>
                <TeamSectionTitleTypography>
                  GITHUB USER
                </TeamSectionTitleTypography>
              </TeamSectionTitleBox>
            </Grid>
            <Grid item xs={2.1} justifyContent="left">
              <TeamSectionTitleBox>
                <TeamSectionTitleTypography>ROLE</TeamSectionTitleTypography>
              </TeamSectionTitleBox>
            </Grid>
          </Grid>
        </Box>
        <OverflowBox>
          {allTeamMembers.map((member: TeamMember, index: number) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                padding: "10px",
              }}
            >
              <Grid container alignItems="center">
                <Grid item xs={0.9}>
                  <TeamSectionTitleBox>
                    <Checkbox
                      key={index}
                      checked={selectedTeamMembers[index].selected}
                      onChange={() => handleChangeTeamMemberSelected(index)}
                      size="small"
                      color="info"
                    />
                  </TeamSectionTitleBox>
                </Grid>
                <Grid item xs={2}>
                  <Grid container alignItems="center">
                    <Grid item xs={3}></Grid>
                    <Grid item xs={9}>
                      <TeamSectionBox>
                        <img
                          src={avatars[index % 6]}
                          alt={`Avatar${index}`}
                          width="28px"
                          height="25px"
                          style={{ paddingRight: "16px" }}
                        />
                        {selectedTeamMembers[index].selected ? (
                          <TeamSectionTitleTypography
                            sx={{ color: "rgba(58, 53, 65, 0.87)" }}
                          >
                            {member.name}
                          </TeamSectionTitleTypography>
                        ) : (
                          <TeamSectionTitleTypography
                            sx={{ color: "rgba(180, 180, 180, 0.87)" }}
                          >
                            {member.name}
                          </TeamSectionTitleTypography>
                        )}
                      </TeamSectionBox>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={2}>
                  <Grid container alignItems="center">
                    <Grid item xs={4}></Grid>
                    <Grid item xs={8}>
                      <TeamSectionBox>
                        {selectedTeamMembers[index].selected ? (
                          <TeamSectionTitleTypography
                            sx={{ color: "rgba(0, 71, 255, 0.43)" }}
                          >
                            {member.atlassianUsername}
                          </TeamSectionTitleTypography>
                        ) : (
                          <TeamSectionTitleTypography
                            sx={{ color: "rgba(180, 180, 180, 0.87)" }}
                          >
                            {member.atlassianUsername}
                          </TeamSectionTitleTypography>
                        )}
                      </TeamSectionBox>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={2}>
                  <TeamSectionTitleBox>
                    {member.email && (
                      <img
                        src={slackIcon}
                        alt="SlackIcon"
                        width="16px"
                        height="16px"
                        style={{ paddingRight: "10px" }}
                      />
                    )}
                    <TeamSectionTitleTypography>
                      {member.email}
                    </TeamSectionTitleTypography>
                  </TeamSectionTitleBox>
                </Grid>
                <Grid item xs={2}>
                  <GitHubSelect
                    index={index}
                    githubData={githubTeamMembers}
                    data={allTeamMembers}
                    setData={setAllTeamMembers}
                  />
                </Grid>
                <Grid item xs={2.1}>
                  {member.role?.length !== 0 && (
                    <TeamSectionTitleBox>
                      <IOSSwitch
                        checked={switchChecked[index]}
                        onChange={() => handleSwitchChange(index)}
                        sx={{ m: 1 }}
                      />
                      <TeamSectionTitleTypography>
                        {member.role}
                      </TeamSectionTitleTypography>
                    </TeamSectionTitleBox>
                  )}
                </Grid>
              </Grid>
            </Box>
          ))}
        </OverflowBox>
        <ButtonPositionBox>
          <Button
            sx={{
              display: "flex",
              alignItems: "center",
              color: "#ffffff",
              backgroundColor: "#3B82F6",
              paddingY: "0.5rem",
              paddingX: "1.5rem",
              ":hover": {
                backgroundColor: "#0B82F6",
              },
            }}
            variant="contained"
            color="info"
            onClick={handleNextButton}
          >
            Next
          </Button>
        </ButtonPositionBox>
      </TeamMainBox>
    </div>
  ) : (
    <div className="mainlayout">
      <Box sx={{
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}>
      <Loader />
      </Box>
    </div>
  );
};

export default TeamsPage;

import Grid from "@mui/material/Grid";
import React, { useCallback, useEffect, useState } from "react";
import { CustomizedSteppers } from "./Stepper";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { IntegrationCard } from "./IntegrationCard";
import { IntegrationContentShowcase } from "./IntegrationShowcase";
import { useLocation, useNavigate } from 'react-router-dom';
import { createInitialReportDuringOnboarding, createReportsSchedule, createTeam, getCommunicationManagementIntegration, getProjectManagementIntegration, getSoftwareManagementIntegration, matchTeamMembers, saveAlertsScheduleDuringOnBoarding } from "../../services/api";
import { useUser } from "../../context/UserContext";
import CircularProgress from "@mui/material/CircularProgress";


import { 
    JIRA_INTEGRATION_URL,
    SLACK_INTEGRATION_URL,
    GITHUB_INTEGRATION_URL,
} from '../../default/urls';
import { OnboardingDoneCard } from "./OnboardingDoneCard";
import { IntegrationDoneContentShowcase } from "./IntegrationDoneShowcase";
import Loader from "../../components/Loader";
import { alertsData } from "../../services/data";
import { Alert } from "../../types";

const extractStepFromQueryParam = (queryParams: URLSearchParams) => {

    const activeStep = queryParams.get('step') || '0';

    return Math.min(parseInt(activeStep), 2);
}

export const ConnectIntegrations: React.FC = () => {

    const [activeStep, setActiveStep] = useState(1);
    const [onboardingComplete, setOnboardingComplete] = useState<boolean>(false);
    const [activeIntegrations, setActiveIntegrations] = useState<boolean[]>([
        false, false, false,
    ]);
    const [loading, setLoading] = useState(true);
    const [isLaunchingWingman, setIsLaunchingWingman] = useState<boolean>(false);

    const { user } = useUser();

    const integrationLinks = [
        JIRA_INTEGRATION_URL || '',
        GITHUB_INTEGRATION_URL|| '',
        SLACK_INTEGRATION_URL || '',
    ];

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const stepFromParams = extractStepFromQueryParam(queryParams)


    const handleNext = () => {
        setActiveStep((prevStep) => Math.min(prevStep + 1, 2)); // Replace 2 with your max step index
    };

    const handleBack = () => {
        setActiveStep((prevStep) => Math.max(prevStep - 1, 0)); // Ensure step does not go below 0
    };

    const fetchIntegrationsData = useCallback(async () => {
        if (!user) return;
        
        try {
            const projectManagementIntegration = await getProjectManagementIntegration(user.organizationId);
            const softwareManagementIntegration = await getSoftwareManagementIntegration(user.organizationId);
            const communicationManagementIntegration = await getCommunicationManagementIntegration(user.organizationId);

            const connections = [
                projectManagementIntegration !== null && projectManagementIntegration.length > 0,
                softwareManagementIntegration !== null && softwareManagementIntegration.length > 0,
                communicationManagementIntegration !== null && communicationManagementIntegration.length > 0,
            ];

            setActiveIntegrations(connections);
            setLoading(false);

            console.log('Integrations:', connections);

            const trueCount = connections.reduce((count, value) => count + (value ? 1 : 0), 0);
            console.log('True Count:', trueCount);

            if (trueCount === 3) {
                setOnboardingComplete(true);
            }
        } catch (error) {
            console.error('Error fetching integrations:', error);
        }
    }, [user, setActiveIntegrations, setLoading, setOnboardingComplete]);

    const navigate = useNavigate();
    const organizationId = localStorage.getItem("organizationId") ?? "";
    const userId = localStorage.getItem("userId") ?? "";

    const launchWingmanOnClick = async(): Promise<void> => {
        setIsLaunchingWingman(true);
        // Create the Initial Report
        const initialReportResponse = await createInitialReportDuringOnboarding(
            organizationId,
            userId
        );

        console.log('Created Initial Report ', initialReportResponse);

        // Register all alerts and reports
        const alerts = alertsData(
            'HIGH',
            5,
            24,
            12,
            10,
        );
        const selectedAlerts: Alert[] = []
        selectedAlerts.push(...alerts.jira)
        selectedAlerts.push(...alerts.github);
        await saveAlertsScheduleDuringOnBoarding(selectedAlerts, organizationId, userId);
        // Build the Team

        await createReportsSchedule([], 8, 5, userId, organizationId);

    try {
        const matchedTeamMembersResponse = await matchTeamMembers(
            organizationId,
            userId
        );
        const matchedTeamMembers = matchedTeamMembersResponse?.matchedTeamMembers || [];
        await createTeam(matchedTeamMembers, organizationId, userId);
    }catch(error){
    
    }

        // setIsLaunchingWingman(false);
        if(initialReportResponse && initialReportResponse.conversationId){
        console.log('Navigating to the right conversation ', initialReportResponse);
            navigate(`/conversation/${initialReportResponse.conversationId}`);
            return;
        }
        navigate('/');
        return;
    }
    
    useEffect(()=>{
        setActiveStep(stepFromParams);
    },[stepFromParams])

    useEffect(() => {
        // Separate useEffect for the event listener
        const handleMessage = async (event: any) => {
            if (event.data.context === 'thirdPartyIntegrationAuthentication') {
                if(event.data.activeStep === activeStep){
                    const newActiveStep = event.data.activeStep === 2 ? event.data.activeStep : activeStep + 1;
                    setActiveStep(newActiveStep); 
                    try {
                        await fetchIntegrationsData();
                    } catch (error) {
                        console.error('Error during integration data fetch', error);
                    }
                }
            }
        };

        window.addEventListener('message', handleMessage);

        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, [activeStep, fetchIntegrationsData]);
    

  // Fetch the API data on mount and when the state changes
    useEffect(() => {

        if(user && loading){
            fetchIntegrationsData();
        }
    }, [user, fetchIntegrationsData, loading]); // Empty dependency array, meaning it runs only once on mount

    if (loading) {
        return (
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
            <CircularProgress />
        </Box>
        );
    }

    if(isLaunchingWingman){
        return (
          <Box
            sx={{
                position: "absolute", // Makes the box cover the whole screen
                top: 0,                // Ensures it starts from the top of the page
                left: 0,               // Ensures it starts from the left of the page
                width: "100vw",        // Full viewport width
                height: "100vh",       // Full viewport height
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
          >
            <Loader />
          </Box>
        )
    }

    return (

        <Grid container style={{ height: '100vh', padding:'25px' }}>
            {/* Left Side */}
            <Grid 
                item 
                xs={6} 
                style={{ 
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="flex-start" // Move content upwards
                    height="100%" // Adjust height to pull items upwards
                    width="100%" // You can adjust this width to control the content width
                    paddingRight={'25px'}
                >
                {/* Stepper */}
                <Box width="100%" mb={2}>
                    <CustomizedSteppers 
                        activeStep={activeStep} 
                        integrationConnectionStatus={activeIntegrations}/>
                </Box>

                {/* Card */}
                <Box width="100%" mb={2} marginTop={'50px'}>
                    {onboardingComplete 
                        ? <OnboardingDoneCard launchingWingman={launchWingmanOnClick}/> 
                        : <IntegrationCard  
                            activeStep={activeStep} 
                            integrationLink={integrationLinks[activeStep]} 
                            isConnected={activeIntegrations[activeStep]}
                        />
                    }
                </Box>

                {/* Buttons */}
                {!onboardingComplete &&
                    <Box 
                        display="flex" 
                        justifyContent="space-between" 
                        width="20%"
                        mt={6} // Add some space above the buttons
                    >
                        <Button 
                            variant="contained" 
                            color="primary" 
                            onClick={handleBack} 
                            disabled={activeStep === 0}
                            sx={{}} // Disable back button if on first step
                            >
                            Back
                        </Button>
                        <Button 
                            variant="contained" 
                            color="secondary" 
                            onClick={handleNext} 
                            disabled={activeStep === 2} // Replace 2 with your max step index
                            >
                            Next
                        </Button>
                    </Box>
                }
                </Box>
            </Grid>

            {/* Right Side */}
            <Grid 
                item 
                xs={6} 
                className='onboardingConnectRightLayout'
                style={{ 
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
                sx={{
                    //border: '2px solid black', // Adds a black border
                    //border: '2px solid black', // Adds a black border
                    boxShadow: '2px 2px 2px 2px rgba(0, 0, 0, 0)', // Adds shadow
                    borderRadius: '10px' // Optional: adds rounded corners
                }} >
                {/* Right side content can go here */}
                {onboardingComplete 
                    ? <IntegrationDoneContentShowcase />
                    : <IntegrationContentShowcase activeStep={activeStep}/>
                } 
            </Grid>
        </Grid>
    );
};
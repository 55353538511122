import { useEffect, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';

import { 
  createProjectManagementIntegration, 
  createCommunicationManagementIntegration, 
  createSoftwareManagementIntegration, 
  syncProjectBoards,
  syncSoftwareRepositories,
  syncProjectIssues,
  syncSoftwarePullRequests
} from '../../services/api';
import { 
  CommunicationManagementProviders,
  ProjectManagementProviders,
  SoftwareManagementProviders
} from '../../default/enums';
import { useUser } from '../../context/UserContext';
import { emptyPageLoaderStyle } from '../../default/theme';
import { useAuth } from '../../context/AuthContext';

const IntegrationCallbackPage = () => {
    const { search, pathname } = useLocation();
    const navigate = useNavigate();
    const { user, removeUserInformation } = useUser();
    const { logout } = useAuth();


    const createIntegration = useCallback( async() => {
        const searchParams = new URLSearchParams(search);
        const code = searchParams.get('code');
        
        let activeStep: number = 0;
        try {
            
            if (!user){
                logout();
                removeUserInformation();
                navigate('/auth/signin');
                return;
            }

        if (pathname === '/jira-login-callback') {

            console.log('Creating Jira Integration with ', code, ' and org ', user.organizationId ,'  and user :', user.id)
            
            await createProjectManagementIntegration(
                code as string, 
                user.organizationId, 
                user.id, 
                ProjectManagementProviders.JIRA
            );

            syncProjectBoards(user.organizationId).then(()=>{
                syncProjectIssues(
                    user.organizationId,
                    user.id,
                );
            });
            activeStep = 0;

        } else if (pathname === '/slack') {

            await createCommunicationManagementIntegration(
                code as string,
                user.organizationId,
                user.id,
                CommunicationManagementProviders.SLACK,
            );
            activeStep = 2;
            
        } else if (pathname === '/github-callback') {

            const installationId = searchParams.get('installation_id');
            await createSoftwareManagementIntegration(
                code as string, 
                Number(installationId) as number, 
                user.organizationId, 
                SoftwareManagementProviders.GITHUB,
            );
            // add call to sync software data
            syncSoftwareRepositories(user.organizationId, user.id).then(()=>{
                syncSoftwarePullRequests(user.organizationId);
            });
            activeStep = 1;
        } 
        
        } catch (error) {
        console.error('Create callback error:', error);
        }

        if (window.opener) {
            // Send a message to the main window
            window.opener.postMessage(
                { 
                    context: 'thirdPartyIntegrationAuthentication',
                    activeStep: activeStep
                }, '*');        
            window.close();
        }
        
    }, [search, pathname, navigate, user, logout, removeUserInformation]);

    useEffect(() => {
 
            createIntegration();
    }, [createIntegration]);

  return (
    <CircularProgress sx={emptyPageLoaderStyle} />
  );
}

export default IntegrationCallbackPage;
import { Routes, Route, Navigate } from 'react-router-dom';
import ChatsPage from './pages/Chats';
import ConversationPage from './pages/Conversation';
import AlertsPage from './pages/Alerts';
import TeamsPage from './pages/Team';
import IntegrationsPage from './pages/Integrations';
import IntegrationCallbackPage from './pages/IntegrationCallback';
import SignUpPage from './pages/SignUp';
import NotificationsPage from './pages/Notifications';
import SignInPage from './pages/SignIn';
import ReportPage from './pages/Report';
import { useAuth } from './context/AuthContext';
import { ConnectIntegrations } from './pages/Onboarding/Integrations';


const AppRoutes = () => {
    const { isAuthenticated, isLoading } = useAuth();

    console.log(`Is User Authinticated - Routes ${isAuthenticated}`);
    if (isLoading) {
        return <div>Loading...</div>;
    };

  return (
    <Routes>
      {
        !isAuthenticated && 
        <>
            <Route path="/*" element={<Navigate to="/auth/signin" replace />} /> 
        </>
      }
      {
        isAuthenticated &&
          <>
            <Route path="/" element={<ChatsPage postHogClient />} />
            <Route path="/alerts" element={<AlertsPage />} />
            <Route path="/conversation/:conversationId" element={<ConversationPage />} />
            <Route path="/onboarding/integrations" element={<IntegrationsPage />} />
            <Route path="/integrations" element={<IntegrationsPage />} />
            <Route path="/onboarding/notifications" element={<NotificationsPage />} />
            <Route path="/onboarding/report" element={<ReportPage />} />
            <Route path="/onboarding/teams" element={<TeamsPage />} />
            <Route path="/onboarding/connect-integrations" element={<ConnectIntegrations />} />
            <Route path="/jira-login-callback" element={<IntegrationCallbackPage />} />
            <Route path="/slack" element={<IntegrationCallbackPage />} />
            <Route path="/github-callback" element={<IntegrationCallbackPage />} />
            </>
      }
        <Route path="/onboarding/registration" element={<SignUpPage />} />
        <Route path="/auth/signin" element={<SignInPage />} />
    </Routes>
  );
}

export default AppRoutes;

import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { SectionTitleTypography } from "../CustomComponents/NotificationTypography";
import { OverflowBox } from "../CustomComponents/NotificationBox";
import { Button } from "@mui/material";
import { ButtonPositionBox } from "../CustomComponents/NotificationBox";

import { Jira1, Jira2, Jira3, Jira4, Jira5 } from "../Jira";
import { GitHub1, GitHub2, GitHub3, GitHub4 } from "../GitHub";
import { Reports1, Reports2, Reports3 } from "../Reports";
import { createReportsSchedule } from "../../services/api";
import { Alert } from "../../types";
import { alertsData } from "../../services/data";

export default function NotificationGrids() {

    const createAlertsFromUserSelection = (
        alertsSelectionState: boolean[],
        bugPriority: string,
        ticketsThreshold: number,
        hours: number,
        hours1: number,
        commentsThreshold: number,
    ): Alert[] => {
        const data = alertsData(
            bugPriority,
            ticketsThreshold,
            hours,
            hours1,
            commentsThreshold,
        );

        const selectedAlerts1 = data.jira.filter((jira, index) => {
            return alertsSelectionState[index] === true;
        });

        const selectedAlerts2 = data.github.filter((github, index) => {
            return alertsSelectionState[index + data.jira.length] === true;
        });

        const selectedAlerts = selectedAlerts1.concat(selectedAlerts2);

        console.log("selectedAlerts: ", selectedAlerts);

        return selectedAlerts;
    }
  let initialComponentStates = [
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ];
  const [componentStates, setComponentStates] = useState<boolean[]>(
    initialComponentStates
  );
  const [bug, setBug] = useState<string>('HIGHEST');
  const [tickets, setTickets] = useState(5);
  const [hours, setHours] = useState(5);
  const [hours1, setHours1] = useState(5);
  const [comments, setComments] = useState(5);
  const [amhour, setAmhour] = useState(8);
  const [pmhour, setPmhour] = useState(6);

  const navigate = useNavigate();

  const handleClick = (componentId: number) => {
    setComponentStates((prevStates) =>
      prevStates.map((obj, index) => (index === componentId ? !obj : obj))
    );
  };

  const handleNotification = async () => {
    let reportsStatus = false;
    let alertsStatus = false;
    let jiraCount = 5;
    let githubCount = 4;
    let reportCount = 3;

    for (let i = 0; i < jiraCount + githubCount; i++) {
      if (componentStates[i] === true) {
        alertsStatus = true;
        break;
      }
    }

    for (let i = 0; i < reportCount; i++) {
      if (componentStates[jiraCount + githubCount + i] === true) {
        reportsStatus = true;
        break;
      }
    }

    const userId = localStorage.getItem("userId") ?? "";
    const organizationId = localStorage.getItem("organizationId") ?? "";

    try {
      if (alertsStatus) {
        const selectedAlerts = createAlertsFromUserSelection(
            componentStates,
            bug,
            tickets,
            hours,
            hours1,
            comments,
        )
        console.log('Selected Alerts List ', selectedAlerts);
        localStorage.setItem('selected-alerts', JSON.stringify(selectedAlerts));
      }
      if (reportsStatus) {
        await createReportsSchedule(
          componentStates,
          amhour,
          pmhour,
          userId,
          organizationId
        );
      }

      navigate("/onboarding/integrations");
    } catch (error) {
      console.error("Failed to register user", error);
    }
  };

  return (
    <div>
      <Box>
        <Grid container spacing={4}>
          <Grid item xs={4}>
            <SectionTitleTypography>Jira</SectionTitleTypography>
          </Grid>
          <Grid item xs={4}>
            <SectionTitleTypography>GitHub</SectionTitleTypography>
          </Grid>
          <Grid item xs={4}>
            <SectionTitleTypography>Reports</SectionTitleTypography>
          </Grid>
        </Grid>
        <OverflowBox>
          <Grid container spacing={4}>
            <Grid item xs={4}>
              <Box>
                <Grid container spacing={3}>
                  <Grid item xs={12} onClick={() => handleClick(0)}>
                    <Jira1 componentStates={componentStates} click={0} />
                  </Grid>
                  <Grid item xs={12} onClick={() => handleClick(1)}>
                    <Jira2
                      componentStates={componentStates}
                      setComponentStates={setComponentStates}
                      click={1}
                      bug={bug}
                      setBug={setBug}
                    />
                  </Grid>
                  <Grid item xs={12} onClick={() => handleClick(2)}>
                    <Jira3 componentStates={componentStates} click={2} />
                  </Grid>
                  <Grid item xs={12} onClick={() => handleClick(3)}>
                    <Jira4 componentStates={componentStates} click={3} />
                  </Grid>
                  <Grid item xs={12} onClick={() => handleClick(4)}>
                    <Jira5
                      componentStates={componentStates}
                      setComponentStates={setComponentStates}
                      click={4}
                      tickets={tickets}
                      setTickets={setTickets}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box>
                <Grid container spacing={3}>
                  <Grid item xs={12} onClick={() => handleClick(5)}>
                    <GitHub1 componentStates={componentStates} click={5} />
                  </Grid>
                  <Grid item xs={12} onClick={() => handleClick(6)}>
                    <GitHub2
                      componentStates={componentStates}
                      setComponentStates={setComponentStates}
                      click={6}
                      hours={hours}
                      setHours={setHours}
                    />
                  </Grid>
                  <Grid item xs={12} onClick={() => handleClick(7)}>
                    <GitHub3
                      componentStates={componentStates}
                      setComponentStates={setComponentStates}
                      click={7}
                      hours1={hours1}
                      setHours1={setHours1}
                    />
                  </Grid>
                  <Grid item xs={12} onClick={() => handleClick(8)}>
                    <GitHub4
                      componentStates={componentStates}
                      setComponentStates={setComponentStates}
                      click={8}
                      comments={comments}
                      setComments={setComments}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box>
                <Grid container spacing={3}>
                  <Grid item xs={12} onClick={() => handleClick(9)}>
                    <Reports1
                      componentStates={componentStates}
                      setComponentStates={setComponentStates}
                      click={9}
                      amhour={amhour}
                      setAmhour={setAmhour}
                    />
                  </Grid>
                  <Grid item xs={12} onClick={() => handleClick(10)}>
                    <Reports2
                      componentStates={componentStates}
                      setComponentStates={setComponentStates}
                      click={10}
                      pmhour={pmhour}
                      setPmhour={setPmhour}
                    />
                  </Grid>
                  <Grid item xs={12} onClick={() => handleClick(11)}>
                    <Reports3 componentStates={componentStates} click={11} />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </OverflowBox>
      </Box>
      <ButtonPositionBox>
        <Button
          sx={{
            display: "flex",
            alignItems: "center",
            color: "#ffffff",
            backgroundColor: "#3B82F6",
            paddingY: "0.5rem",
            paddingX: "1.5rem",
            ":hover": {
              backgroundColor: "#0B82F6",
            },
          }}
          variant="contained"
          color="info"
          onClick={handleNotification}
        >
          Next
        </Button>
      </ButtonPositionBox>
    </div>
  );
}
